<template>
    <div class="recruit">
        <div class="row">
            <div class="col">
                <img src="../assets/recruit.png" alt="採用情報" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <Breadcrumb :breadcrumbs="breadcrumbs"></Breadcrumb>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="tabs">
                    <ul class="nav nav-tabs justify-content-end" role="tablist">
                        <li class="nav-item">
                            <button class="nav-link active" id="career-tab" data-bs-toggle="tab"
                                data-bs-target="#career" type="button" role="tab" aria-controls="career"
                                aria-selected="true">キャリア採用</button>
                        </li>
                        <li class="nav-item">
                            <button class="nav-link" id="new-graduate-tab" data-bs-toggle="tab"
                                data-bs-target="#new-graduate" type="button" role="tab" aria-controls="new-graduate"
                                aria-selected="false">新卒採用</button>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="career" role="tabpanel" aria-labelledby="career-tab">
                            <h3>求める人物像</h3>
                            <p class="p-3">
                                自らの考えをもって行動できる人<br />
                                目標にチャレンジする意欲・姿勢を持った人<br />
                                自らを向上させていこうとする意欲・姿勢を持った人<br />
                                お客様と同じ目線で物事を考え、意識し、誠実に行動できる人<br /><br />

                                このような考えや姿勢を持った人材が集まり、力を合わせることで新しい発想を生み出すことができると信じています。<br />
                                明るく元気で、前向きな気持ちのあふれた方々のご応募をお待ちしております。
                            </p>
                            <h3>募集要項</h3>
                            <div class="p-3">
                                <h4>技術職</h4>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th class="table-warning">採用職種</th>
                                            <td>
                                                <p>
                                                    プロジェクトマネージャー（PM）<br />
                                                    プロジェクトリーダー（PL）<br />
                                                    システムエンジニア(SE)<br />
                                                    プログラマー(PG)<br />
                                                    インフラ、ネットワーク経験者</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">募集形態</th>
                                            <td>正社員 / 契約社員<br />
                                                フリーランスエンジニアは大歓迎です</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">応募資格</th>
                                            <td>
                                                <p>
                                                    学歴 / 国籍 / 性別不問。<br /><br />

                                                    【必須経験・知識】<br />
                                                    プロジェクトマネージャー（PM）／プロジェクトリーダー（PL）<br />
                                                    ・プロジェクトマネージメント（全体統括管理・推進、進捗管理、要員管理、課題管理等）経験<br /><br />

                                                    システムエンジニア(SE)／プログラマー(PG)<br />
                                                    ・要件定義、概要設計、詳細設計、プログラミング、テスト検証設計、総合テスト<br />
                                                    ・アプリケーション開発経験（JAVA、PYTHON、C#/VB.NET/ASP.NET、C/C++等）<br />
                                                    ・モバイル開発経験（iOS、Android）<br />
                                                    ・インフラ、ネットワーク開発経験（サーバ構築、ミドルウェア、AWS、Azure、GCP）<br /><br />

                                                    【あると望ましい経験・知識】<br />
                                                    ・円滑なコミュニケーションができる方</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">給与待遇</th>
                                            <td>経験、能力、保有資格、前給を考慮の上、協議して決定します。昇給年１回、通勤交通費別途支給</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">休日休暇</th>
                                            <td>完全週休2日制(土・日)・祝日・年末年始・有給休暇</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">勤務時間</th>
                                            <td>9:00～18:00（休憩1時間） ※勤務地により変動あり</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">勤務地</th>
                                            <td>自社、客先</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">福利厚生</th>
                                            <td>
                                                <p>
                                                    厚生年金保険／健康保険<br />
                                                    労災保険／雇用保険<br />
                                                    健康診断制度<br />
                                                    研修制度</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="p-3">
                                <h4>営業職</h4>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th class="table-warning">採用職種</th>
                                            <td>営業</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">募集形態</th>
                                            <td>正社員 / 契約社員</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">応募資格</th>
                                            <td>
                                                <p>
                                                    学歴 / 国籍 / 性別不問。<br /><br />

                                                    【あると望ましい経験・知識】<br />
                                                    ・IT業界またはSES（常駐型技術支援サービス）業界での営業経験を有することが望ましい<br />
                                                    ・技術的な知識や理解を持ち、技術的な問題やニーズを理解し、顧客に適切なソリューションを提供できることが望ましい<br />
                                                    ・顧客とのコミュニケーション能力が高く、顧客の要求や課題に対して迅速かつ適切に対応できることが求められる<br />
                                                    ・ビジネススキルや交渉力、問題解決能力があり、目標達成に向けて自己管理能力を持ち合わせていることが求められる<br />
                                                    ・新規顧客獲得や既存顧客の維持に積極的に取り組み、営業成績を向上させる意欲があることが望ましい</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">給与待遇</th>
                                            <td>
                                                <p>
                                                    基本給：責任と経験に応じて定められる基本給を支給します。<br />
                                                    賞与：年次業績に応じた賞与が支給されます。業績が良好であれば、賞与の割合が増加する可能性があります。</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">休日休暇</th>
                                            <td>完全週休2日制(土・日)・祝日・年末年始・有給休暇</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">勤務時間</th>
                                            <td>9:00～18:00（休憩1時間）</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">勤務地</th>
                                            <td>自社</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">福利厚生</th>
                                            <td>
                                                <p>
                                                    厚生年金保険／健康保険<br />
                                                    労災保険／雇用保険<br />
                                                    健康診断制度</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="p-3">
                                <h4>教育職</h4>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th class="table-warning">採用職種</th>
                                            <td>ITトレーニング講師</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">募集形態</th>
                                            <td>正社員 / 契約社員<br>
                                                フリーランスエンジニアは大歓迎です
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">応募資格</th>
                                            <td>
                                                <p>
                                                    学歴 / 国籍 / 性別不問。<br /><br />
                                                    【あると望ましい経験・知識】<br />
                                                    ・10年以上の実務経験を有し、開発業務においてリーダーやサブリーダーとして活躍した方<br />
                                                    ・教育や指導経験がある方、または教育に興味を持ち、人を育てることに情熱を持つ方<br />
                                                    ・コミュニケーション能力が高く、受講生とのコミュニケーションが円滑に図れる方<br />
                                                    ・柔軟性があり、変化する環境に適応し、最新の技術トレンドに常に関心を持つ方
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">給与待遇</th>
                                            <td>
                                                <p>経験、能力、保有資格、前給を考慮の上、協議して決定します。昇給年１回、通勤交通費別途支給</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">休日休暇</th>
                                            <td>完全週休2日制(土・日)・祝日・年末年始・有給休暇</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">勤務時間</th>
                                            <td>9:00～18:00（休憩1時間）</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">勤務地</th>
                                            <td>自社、またはテレワーク</td>
                                        </tr>
                                        <tr>
                                            <th class="table-warning">福利厚生</th>
                                            <td>
                                                <p>
                                                    厚生年金保険／健康保険<br />
                                                    労災保険／雇用保険<br />
                                                    健康診断制度</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3>応募方法</h3>
                            <p class="p-3">
                                職務経歴書または当社所定の<a href="https://friendsystems.co.jp/doc/職務経歴書_氏名.xlsx">スキルシート</a>を記入した上、
                                info<i class="bi bi-c-circle"></i>friendsystems.co.jpまでご送付ください。<br /><br />
                                ※ご提供いただきました個人情報につきましては、当社の<a href="/privacypolicy">個人情報保護方針</a>に基づきお取り扱い致します。<br />
                                ※書類選考の上、合格の方には5営業日以内に面接日等を連絡させていただきます。<br />
                                ※応募書類を返却いたしませんことを予めご了承ください。
                            </p>
                        </div>
                        <div class="tab-pane fade" id="new-graduate" role="tabpanel" aria-labelledby="new-graduate-tab">
                            <p class="p-3">
                                大変申し訳ありませんが、全ての職種について現在、求人募集は行っておりません。<br />
                                募集の際は当ページにてご案内いたします。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
    name: "RecruitPage",
    components: {
        Breadcrumb,
    },
    computed: {
        breadcrumbs: function () {
            return {
                data: [{
                    name: "ホーム",
                    path: "/home",
                },
                {
                    name: "採用情報",
                },
                ],
            };
        },
    },
    methods: {},
};
</script>

<style scoped>
.recruit .nav-tabs .nav-link:not(.active) {
    color: #28A7E1;
    /* タブの文字色を設定 */
}

.recruit a {
    color: #28A7E1;
    text-decoration: none;
}

.recruit a:hover {
    text-decoration: underline;
}
</style>
